<template>
  <div class="local">
    <Nav></Nav>
    <div class="local-con desktop-present">
      <div class="local-level">
        <div class="level-one" v-if="selectIds.length > 0">
          <div class="one-name" :class="{ active: selectIds[0].id == 13, pink: 13 != selectIds[selectIds.length - 1].id && selectIds[0].id == 13 }" @click="onChangeLocal(13)">
            合肥
          </div>
          <div class="one-name" :class="{ active: selectIds[0].id == 12, pink: 12 != selectIds[selectIds.length - 1].id && selectIds[0].id == 12 }" @click="onChangeLocal(12)">
            安徽
          </div>
        </div>
        <div class="two-icon el-icon-d-arrow-left"></div>
        <div class="level-two" v-if="selectIds.length > 1">
          <div
            class="two-name"
            v-for="(k, twoindex) in twoList"
            :key="k.id + 'two_' + twoindex"
            :class="{ active: selectIds[1].id == k.id && k.id == selectIds[selectIds.length - 1].id, pink: k.id != selectIds[selectIds.length - 1].id && selectIds[1].id == k.id }"
            @click="onTwoPost(k)"
          >
            {{ k.name }}
          </div>
        </div>
        <div class="level-two" v-if="selectIds.length == 1">
          <div
            class="two-name"
            v-for="(k, twoindex) in twoList"
            :key="k.id + 'two_' + twoindex"
            :class="{ active: selectIds[0].id == k.id && k.id == selectIds[selectIds.length - 1].id, pink: k.id != selectIds[selectIds.length - 1].id && selectIds[0].id == k.id }"
            @click="onTwoPost(k)"
          >
            {{ k.name }}
          </div>
        </div>
        <div class="three-icon el-icon-d-arrow-left" v-if="selectIds.length >= 2"></div>
        <div class="level-three" v-if="selectIds.length > 2">
          <div class="three-name" v-for="m in threeList" :key="m.id" :class="{ active: selectIds[2].id == m.id }" @click="onthreePost(m)">
            {{ m.name }}
          </div>
        </div>
        <div class="level-three" v-if="selectIds.length == 2">
          <div class="three-name" v-for="m in threeList" :key="m.id" :class="{ active: selectIds[1].id == m.id }" @click="onthreePost(m)">
            {{ m.name }}
          </div>
        </div>
      </div>
      <div class="page-container">
        <el-pagination
          @current-change="handleCurrentChange"
          :current-page.sync="currentPage"
          :page-size="12"
          :hide-on-single-page="true"
          layout="prev, pager, next, jumper"
          :total="downTotal"
        ></el-pagination>
      </div>
      <div class="local-post">
        <div class="current-local">
          <span>{{ currentLocalName }}</span
          >地区下的稿件:
        </div>
        <div class="post-con" v-for="p in postList" :key="p.id">
          <a class="post-img" :style="{ backgroundImage: `url(` + p.postImg + `)` }" :href="'post.html?id=' + p.id" target="blank"></a>
          <!-- <div class="post-img" :style="{ backgroundImage: `url(` + p.postImg + `)` }" @click="onLookPost(p.id)"></div> -->
          <div class="post-text">{{ p.title }}</div>
        </div>
      </div>
    </div>
    <div class="local-con mobile-present">
      <div class="local-level">
        <div class="level-one" v-if="selectIds.length > 0">
          <div class="one-name" :class="{ active: selectIds[0].id == 13, pink: 13 != selectIds[selectIds.length - 1].id && selectIds[0].id == 13 }" @click="onChangeLocal(13)">
            合肥
          </div>
          <div class="one-name" :class="{ active: selectIds[0].id == 12, pink: 12 != selectIds[selectIds.length - 1].id && selectIds[0].id == 12 }" @click="onChangeLocal(12)">
            安徽
          </div>
        </div>
        <div class="two-icon el-icon-d-arrow-left"></div>
        <div class="level-two" v-if="selectIds.length > 1">
          <div
            class="two-name"
            v-for="(k, twoindex) in twoList"
            :key="k.id + 'two_' + twoindex"
            :class="{ active: selectIds[1].id == k.id && k.id == selectIds[selectIds.length - 1].id, pink: k.id != selectIds[selectIds.length - 1].id && selectIds[1].id == k.id }"
            @click="onTwoPost(k)"
          >
            {{ k.name }}
          </div>
        </div>
        <div class="level-two" v-if="selectIds.length == 1">
          <div
            class="two-name"
            v-for="(k, twoindex) in twoList"
            :key="k.id + 'two_' + twoindex"
            :class="{ active: selectIds[0].id == k.id && k.id == selectIds[selectIds.length - 1].id, pink: k.id != selectIds[selectIds.length - 1].id && selectIds[0].id == k.id }"
            @click="onTwoPost(k)"
          >
            {{ k.name }}
          </div>
        </div>
        <div class="three-icon el-icon-d-arrow-left" v-if="selectIds.length >= 2"></div>
        <div class="level-three" v-if="selectIds.length > 2">
          <div class="three-name" v-for="m in threeList" :key="m.id" :class="{ active: selectIds[2].id == m.id }" @click="onthreePost(m)">
            {{ m.name }}
          </div>
        </div>
        <div class="level-three" v-if="selectIds.length == 2">
          <div class="three-name" v-for="m in threeList" :key="m.id" :class="{ active: selectIds[1].id == m.id }" @click="onthreePost(m)">
            {{ m.name }}
          </div>
        </div>
      </div>
      <div class="page-container">
        <el-pagination
          @current-change="handleCurrentChange"
          :current-page.sync="currentPage"
          :page-size="12"
          :hide-on-single-page="true"
          layout="prev, pager, next, jumper"
          :total="downTotal"
        ></el-pagination>
      </div>
      <div class="local-post">
        <div class="current-local">
          <span>{{ currentLocalName }}</span
          >地区下的稿件:
        </div>
        <div class="post-con" v-for="p in postList" :key="p.id">
          <a class="post-img" :style="{ backgroundImage: `url(` + p.postImg + `)` }" :href="'post.html?id=' + p.id" target="blank"></a>
          <!-- <div class="post-img" :style="{ backgroundImage: `url(` + p.postImg + `)` }" @click="onLookPost(p.id)"></div> -->
          <div class="post-text">{{ p.title }}</div>
        </div>
      </div>
    </div>
    <Bottom></Bottom>
  </div>
</template>

<script>
import RD from '@/api/RD'
import Nav from '@/components/nav.vue'
import Bottom from '@/components/bottom.vue'

export default {
  components: { Nav, Bottom },
  data: function () {
    return {
      postList: [], // 稿件列表
      page: 1,
      currentPage: 1,
      downTotal: 1,
      postCount: '',
      query: {},
      twoList: [], // 二级的列表
      threeList: [], // 三级的列表
      selectIds: [],
      currentLocalName: '', // 地区的名字
    }
  },
  created() {
    this.reload()
  },
  methods: {
    reload() {
      var thiz = this
      console.log('quet', thiz.$route.query)
      thiz.refreshData(thiz.$route.query.id)
    },
    refreshData(reloadId) {
      var thiz = this
      RD.addr()
        .id(reloadId)
        .path()
        .then((data) => {
          console.log('root', data)
          thiz.selectIds = []
          data.forEach((k) => {
            thiz.selectIds.push(k)
          })
          console.log('mlml', thiz.selectIds)
          if (data.length == 2) {
            if (thiz.selectIds[0].id == 12) {
              RD.addr()
                .id(12)
                .downAddress()
                .then((data) => {
                  thiz.twoList = data
                  console.log('46222', thiz.selectIds[1].id)
                  thiz.onTwoPost(thiz.selectIds[1])
                  thiz.currentLocalName = thiz.selectIds[1].name
                })
            } else {
              RD.addr()
                .id(13)
                .downAddress()
                .then((data) => {
                  thiz.twoList = data
                  thiz.onTwoPost(thiz.selectIds[0])
                  thiz.currentLocalName = thiz.selectIds[0].name
                })
            }
          } else if (data.length == 3) {
            if (thiz.selectIds[0].id == 12) {
              RD.addr()
                .id(12)
                .downAddress()
                .then((data) => {
                  thiz.twoList = data
                  console.log('jop')
                  RD.addr()
                    .id(thiz.selectIds[1].id)
                    .downAddress()
                    .then((data) => {
                      thiz.threeList = data
                    })
                  thiz.currentLocalName = thiz.selectIds[1].name
                  thiz.onthreePost(thiz.selectIds[2])
                })
            } else {
              // 合肥的
              RD.addr()
                .id(13)
                .downAddress()
                .then((data) => {
                  thiz.twoList = data
                  thiz.currentLocalName = thiz.selectIds[0].name
                })
            }
          } else if (data.length == 1) {
            if (thiz.selectIds[0].id == 12) {
              RD.addr()
                .id(12)
                .downAddress()
                .then((data) => {
                  thiz.twoList = data
                  thiz.currentLocalName = thiz.selectIds[0].name
                  thiz.threeList = []
                })
            } else {
              RD.addr()
                .id(13)
                .downAddress()
                .then((data) => {
                  thiz.twoList = data
                  thiz.currentLocalName = thiz.selectIds[0].name
                })
            }
          }
        })

      thiz.query.addrId = thiz.$route.query.id
      thiz.query.storeIds = [1]
      RD.pure()
        .post()
        .query(thiz.query, thiz.page, 12)
        .then((items) => {
          items.list.forEach((i) => {
            i.postImg = RD.pic().id(i.frontPicId).mImage_URL()
          })
          thiz.postCount = items.pager.total
          thiz.postList = items.list
          thiz.currentPage = items.pager.currentPage
          thiz.downTotal = items.pager.total
        })
    },
    // 二级地区的点击事件
    async onTwoPost(two) {
      console.log('onTwoPost', two)
      var thiz = this
      var data = await RD.addr().id(two.id).path()
      thiz.selectIds = data
      console.log('twopath', thiz.selectIds)

      thiz.selectIds[1].id = two.id
      thiz.currentLocalName = two.name
      thiz.queryPost(two.id)
      RD.addr()
        .id(two.id)
        .downAddress()
        .then((data) => {
          thiz.threeList = data
        })
    },
    // 三级点击
    onthreePost(m) {
      var thiz = this
      console.log('threepost', m)

      RD.addr()
        .id(m.id)
        .path()
        .then((data) => {
          console.log('threePath', data)
          thiz.selectIds = data
        })
      thiz.currentLocalName = m.name

      if (thiz.selectIds.length > 2) {
        thiz.selectIds[2].id = m.id
        thiz.queryPost(m.id)
      } else if (thiz.selectIds.length == 2) {
        thiz.selectIds[1].id = m.id
        thiz.queryPost(m.id)
        console.log('ss', thiz.selectIds)
      }
    },
    queryPost(id) {
      var query = {}
      query.addrId = id
      query.storeIds = [1]
      RD.pure()
        .post()
        .query(query, this.page, 12)
        .then((items) => {
          items.list.forEach((i) => {
            i.postImg = RD.pic().id(i.frontPicId).mImage_URL()
          })
          this.postCount = items.pager.total
          this.postList = items.list
          this.currentPage = items.pager.currentPage
          this.downTotal = items.pager.total
        })
    },
    handleCurrentChange(val) {
      var thiz = this
      thiz.page = val
      thiz.currentPage = val
      thiz.queryPost(thiz.selectIds[1].id)
    },
    onChangeLocal(localId) {
      var thiz = this
      if (localId == 13) {
        thiz.selectIds[0].id = 13
        thiz.refreshData(13)
      } else if (localId == 12) {
        thiz.selectIds[0].id = 12
        thiz.refreshData(12)
      }
    },
  },
}
</script>

<style scoped lang="less">
.local {
  height: auto;
  background-color: #f1f1f1;
  .local-con.desktop-present {
    width: 120rem;
    margin: 0 auto;
    height: auto;
    overflow: hidden;
    .local-level {
      height: auto;
      background-color: #ccc;
      padding: 2rem 0rem;
      .level-one {
        display: flex;
        width: 30rem;
        margin: 0 auto;
        height: 7.5rem;
        .one-name {
          height: 5rem;
          line-height: 5rem;
          font-size: 2rem;
          width: 20rem;
          margin-top: 1rem;
          border-radius: 1rem;
        }
        .one-name.active {
          background-color: red;
          color: #fff;
        }
        .one-name.pink {
          background-color: pink;
        }
      }
      .level-two {
        width: 120rem;
        margin: 0 auto;
        display: flex;
        height: auto;
        flex-wrap: wrap;
        justify-content: center;
        flex-shrink: 0;
        .two-name {
          // min-width: 8.3rem;
          flex: auto;
          height: 4rem;
          line-height: 4rem;
          font-size: 1.5rem;
          border-radius: 1rem;
          border: 0.1rem solid #ccc;
          background-color: #fff;
          margin-right: 0.5rem;
          cursor: pointer;
          margin-bottom: 0.5rem;
        }
        .two-name.active {
          background-color: red;
          color: #fff;
        }
        .two-name.pink {
          background-color: pink;
          color: #fff;
        }
      }
      .level-three {
        width: 100rem;
        margin: 0 auto;
        display: flex;
        height: auto;
        flex-wrap: wrap;
        .three-name {
          // width: 15.5rem;
          flex: auto;
          height: 4rem;
          line-height: 4rem;
          font-size: 1.5rem;
          border-radius: 1rem;
          border: 0.1rem solid #ccc;
          background-color: #fff;
          margin-right: 0.5rem;
          margin-bottom: 1rem;
          cursor: pointer;
          padding: 0rem 1rem;
        }
        .three-name.active {
          background-color: red;
          color: #fff;
        }
      }
      .three-icon,
      .two-icon {
        transform: rotate(-90deg);
        height: 3rem;
        font-size: 2rem;
      }
    }
    .local-post {
      height: auto;
      width: 102%;
      margin-top: 5rem;
      background-color: #fff;
      display: flex;
      flex-wrap: wrap;
      justify-content: flex-start;
      position: relative;
      .current-local {
        position: absolute;
        top: -3.8rem;
        left: 0.5rem;
        font-size: 1.7rem;
        span {
          color: red;
          margin-right: 0.5rem;
        }
      }
      .post-con {
        height: 25rem;
        width: 28.9rem;
        margin-right: 1.5rem;
        position: relative;
        overflow: hidden;
        margin-bottom: 1.5rem;
        a {
          text-decoration: none;
          color: #000;
          display: inline-block;
        }
        .post-img {
          height: 25rem;
          width: 28.9rem;
          background: no-repeat 50% / cover;
          transition: all 200ms ease-in-out;
        }
        .post-text {
          position: absolute;
          bottom: 0rem;
          color: #fff;
          height: 4rem;
          line-height: 4rem;
          transition: all 200ms ease-in-out;
          text-overflow: ellipsis;
          white-space: nowrap;
          font-size: 1.3rem;
          background-color: rgba(0, 0, 0, 0.6);
          overflow: hidden;
          width: 100%;
        }
      }
      .post-con:hover {
        .post-img {
          transform: scale(1.05);
        }
        .post-text {
          opacity: 1;
          font-size: 1.6rem;
        }
      }
    }
    .page-container {
      text-align: right;
      margin-top: 3rem;
    }
  }
  .local-con.mobile-present {
    width: 750px;
    margin: 0 auto;
    height: auto;
    overflow: hidden;
    .local-level {
      height: auto;
      background-color: #ccc;
      padding: 2rem 0rem;
      .level-one {
        display: flex;
        width: 185px;
        margin: 0 auto;
        height: 50px;
        .one-name {
          height: 30px;
          line-height: 30px;
          font-size: 22px;
          width: 92px;
          margin-top: 10px;
          border-radius: 10px;
        }
        .one-name.active {
          background-color: red;
          color: #fff;
        }
        .one-name.pink {
          background-color: pink;
        }
      }
      .level-two {
        width: 750px;
        margin: 0 auto;
        display: flex;
        height: auto;
        flex-wrap: wrap;
        justify-content: center;
        flex-shrink: 0;
        .two-name {
          // min-width: 8.3rem;
          flex: auto;
          height: 40px;
          line-height: 40px;
          font-size: 14px;
          border-radius: 10px;
          border: 0.1rem solid #ccc;
          background-color: #fff;
          margin-right: 0.5rem;
          cursor: pointer;
          margin-bottom: 0.5rem;
        }
        .two-name.active {
          background-color: red;
          color: #fff;
        }
        .two-name.pink {
          background-color: pink;
          color: #fff;
        }
      }
      .level-three {
        width: 750px;
        margin: 0 auto;
        display: flex;
        height: auto;
        flex-wrap: wrap;
        .three-name {
          flex: auto;
          height: 40px;
          line-height: 40px;
          font-size: 14px;
          border-radius: 10px;
          border: 0.1rem solid #ccc;
          background-color: #fff;
          margin-right: 0.5rem;
          margin-bottom: 10px;
          cursor: pointer;
          padding: 0rem 10px;
        }
        .three-name.active {
          background-color: red;
          color: #fff;
        }
      }
      .three-icon,
      .two-icon {
        transform: rotate(-90deg);
        height: 30px;
        font-size: 22px;
      }
    }
    .local-post {
      height: auto;
      width: 100%;
      margin-top: 50px;
      background-color: #fff;
      display: flex;
      flex-wrap: wrap;
      justify-content: flex-start;
      position: relative;
      .current-local {
        position: absolute;
        top: -35px;
        left: 5px;
        font-size: 16px;
        span {
          color: red;
          margin-right: 5px;
        }
      }
      .post-con {
        height: 151px;
        width: 175px;
        margin-right: 12px;
        position: relative;
        overflow: hidden;
        margin-bottom: 12px;
        a {
          text-decoration: none;
          color: #000;
          display: inline-block;
        }
        .post-img {
          height: 100%;
          width: 100%;
          background: no-repeat 50% / cover;
          transition: all 200ms ease-in-out;
        }
        .post-text {
          position: absolute;
          bottom: 0rem;
          color: #fff;
          height: 40px;
          line-height: 40px;
          transition: all 200ms ease-in-out;
          text-overflow: ellipsis;
          white-space: nowrap;
          font-size: 14px;
          background-color: rgba(0, 0, 0, 0.6);
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
          width: 100%;
        }
      }
      .post-con:hover {
        .post-img {
          transform: scale(1.05);
        }
        .post-text {
          opacity: 1;
          font-size: 1.6rem;
        }
      }
    }
    .page-container {
      text-align: right;
      margin-top: 30px;
    }
  }
}
</style>
